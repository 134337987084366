import createStore, { conditionalStore } from '#/store'
import prompts from '$/workers/api/ai/promptCriteria'
import useInDevMode from './useInDevMode.js'

type PromptName = Dx.Ai.Model.Prompt.Name

export type DevModelSelection =
  'default' |
  'custom' |
  `service:${Dx.Ai.Model.Service.Name}` |
  `family:${Dx.Ai.Model.Family}` |
  `name:${Dx.Ai.Model.Name}` |
  `key:${Dx.Ai.Model.Key}`

export type DevModelCriteria = Dx.Ai.Model.Criteria & {
  selection: DevModelSelection
}


type DevPromptCriteriaState = Record<PromptName, {
  value: DevModelCriteria
  update: (fn: (current: DevModelCriteria) => DevModelCriteria) => void
}>

const store = createStore<DevPromptCriteriaState>(set =>
  Object.mapValues(prompts, (criteria, name) => ({
    value: {
      selection: 'default',
      ...criteria,
    },
    update: next => set(s => {
      const prompt = s[name]

      if (prompt) {
        const value = next(prompt.value)

        prompt.value = value.selection === 'default' ?
          { ...prompt.value, selection: value.selection } :
          value as WritableDeep<DevModelCriteria>
      }
    }),
  })),
{
  persist: {
    key: 'dev:promptCriteria',
  },
})


export default conditionalStore(store, () => useInDevMode())
