import createStore, { conditionalStore } from '#/store'
import prompts from '$/workers/api/ai/promptCriteria'
import useInDevMode from './useInDevMode.js'

type PromptName = Dx.Ai.Model.Prompt.Name

type DevloperPromptState = {
  record: (execution: Dx.Ai.State.Execution) => void
  activate: (name: PromptName) => void
  deactivate: (name: PromptName) => void
  setActions: (name: PromptName, actions?: () => React.ReactNode) => void
  setSummary: (name: PromptName, summary: Nullable<string> | false) => void
  executions: Record<PromptName, Dx.Ai.State.Execution[]>
  state: Record<PromptName, {
    active: boolean
    count: number
    actions?: () => React.ReactNode
    summary?: string
  }>
}

const max = 10

const store = createStore<DevloperPromptState>(set => ({
  record: next => set(s => {
    const executions = s.executions[next.operation.name]

    if (executions) {
      s.executions[next.operation.name] = [next, ...executions]
        .orderBy('updatedAt', 'desc')
        .uniqBy('startedAt')
        .slice(0, max)
    }
  }),

  executions: Object.mapValues(prompts, () => []),

  state: Object.mapValues(prompts, () => ({
    active: false,
    count: 0,
    summary: undefined,
  })),

  activate: name => set(s => {
    const prompt = s.state[name]
    
    if (prompt) {
      prompt.count++
      prompt.active = true
    }
  }),

  deactivate: name => set(s => {
    const prompt = s.state[name]

    if (prompt) {
      prompt.count--
      prompt.active = prompt.count > 0
    }
  }),

  setActions: (name, actions) => set(s => {
    const prompt = s.state[name]

    if (prompt) {
      prompt.actions = actions
    }
  }),

  setSummary: (name, summary) => set(s => {
    const prompt = s.state[name]

    if (prompt) {
      prompt.summary = summary || undefined
    }
  }),
}))


export default conditionalStore(store, () => useInDevMode())
