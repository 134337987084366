export default {
  'image:describe': {
    service: 'openai',
    temperature: 1,
    rank: [
      { attr: 'quality', weight: 3, direction: 'desc' },
      { attr: 'speed', weight: 3, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'symptoms:evaluate': {
    key: ['groq/llama-3.1-8b', 'groq/llama-3.1-70b', 'openai/gpt-4o'],
    temperature: 0,
    rank: [
      { attr: 'quality', weight: 2, direction: 'desc' },
      { attr: 'speed', weight: 5, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'symptoms:image': {
    temperature: 0,
    service: 'anthropic',
    name: 'sonnet-3.5',
    rank: [
      { attr: 'quality', weight: 3, direction: 'desc' },
      { attr: 'speed', weight: 3, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'symptoms:chart': {
    service: 'openai',
    temperature: 0,
    rank: [
      { attr: 'quality', weight: 3, direction: 'desc' },
      { attr: 'speed', weight: 3, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'symptoms:questions': {
    key: ['groq/llama-3.1-8b', 'groq/llama-3.1-70b', 'openai/gpt-4o'],
    temperature: 0,
    rank: [
      { attr: 'quality', weight: 2, direction: 'desc' },
      { attr: 'speed', weight: 5, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'assessment:evaluate': {
    service: 'openai',
    temperature: 0,
    rank: [
      { attr: 'quality', weight: 3, direction: 'desc' },
      { attr: 'speed', weight: 3, direction: 'desc' },
      { attr: 'latency', weight: 2, direction: 'asc' },
    ],
  },

  'assessment:generate': {
    service: 'anthropic',
    name: 'sonnet-3.5',
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },

  'assessment:generateSnippet': {
    service: 'anthropic',
    name: 'sonnet-3.5',
    temperature: 1,
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },

  'converse:message': {
    service: 'openai',
    name: 'gpt-4o',
    temperature: 1,
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },

  'page:generateContent': {
    service: 'anthropic',
    name: 'sonnet-3.5',
    temperature: 1,
    parameters: {
      paragraphs: {
        min: 2,
        max: 5,
      },
      words: {
        min: 100,
        max: 350,
      },
    },
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },

  'page:generateSnippet': {
    service: 'anthropic',
    name: 'sonnet-3.5',
    temperature: 1,
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },

  'page:generateSummary': {
    service: 'anthropic',
    name: 'sonnet-3.5',
    temperature: 1,
    rank: [
      { attr: 'quality', weight: 1, direction: 'desc' },
    ],
  },
} satisfies Record<string, Dx.Ai.Model.Criteria>